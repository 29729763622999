import React from "react";

export default function FormDialog(props) {
  const handleSubmit = () => {
    props.addCXAppToken();
  };

  return (
    <div style={{ margin: "10px" }}>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add
      </span>
    </div>
  );
}
