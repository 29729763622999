import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import { useClipboard } from "use-clipboard-copy";

import LoadingProgress from "../../Common/LoadingProgress";
import TagSection from "../../Common/TagSectionTooltip";
import Induction from "../Components/Induction";
import ActionButtonWithMenu from "../Components/ActionButtonWithMenu";
import AddTemplateButton from "../Components/AddTemplateButton";
import AddTemplateTableButton from "../Components/AddTemplateTableButton";

import Header from "../../assets/components/header";
import searchIcon from "./assets/search.svg";
import avatar from "./assets/avatar.png";
import multipulFile from "./assets/multipul-file.svg";

import "./assets/index.css";
import { columns, sms_templates_links } from "../config/smstemplates"

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const CHARACTER_LIMIT = 160;

export default function SMSTemplates(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState([]);
  const [namefilter, setNamefilter] = React.useState(null);
  const [labelfilter, setLabelfilter] = React.useState(null);
  const [contentfilter, setContentfilter] = React.useState(null);
  const [senderfilter, setSenderfilter] = React.useState(null);

  const clipboard = useClipboard({
    onSuccess() {
      alert(`Great! Now copy the link into the "WEBHOOK" feature in your ActiveCampaign automation.`);
    },
    onError() {
      alert("'Failed to copy text!'");
    },
  });

  const onAddTemplateSubmit = (addData) => {
    return {}
  };

  const onDeleteTemplateSubmit = (row) => {
    return {}
  };

  const onEditTemplateSubmit = (row) => {
    return {}
  };

  if (!serverData) {
    return <LoadingProgress />;
  }

  const SMSTableRow = (props) => {
    const { row } = props;
    const rowId = row.id;

    if (!row) {
      return (
        <StyledTableRow hover role="checkbox" tabIndex={-1} key={rowId}>
          <TableCell colSpan={columns.length}>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </TableCell>
        </StyledTableRow>
      );
    }

    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={rowId}>
        {columns.map((column) => {
          if (column.id == "tags") {
            return (
              <StyledTableCell key={column.id} align={column.align}>
                <div style={{ textAlign: "center" }}>
                  <TagSection templateId={row.id} row={row} />
                </div>
              </StyledTableCell>
            );
          }
          const value = row[column.id];
          if (column.id == "template_text") {
            return (
              <StyledTableCell key={column.id} align={column.align}>
                <div className="template-info">
                  <div className="template-info__item">
                    <span>Message : </span>
                    <p className="template-info__item--border">{value}</p>
                  </div>
                  <div className="template-info__item">
                    <span>Total Chars : </span>
                    <p>
                      {value.length} / {CHARACTER_LIMIT}
                    </p>
                  </div>
                </div>
              </StyledTableCell>
            );
          } else if (column.id === "link") {
            const templatePostEndpoint = "templatePostEndpoint?id=" + row.id;
            const value = row["template_text"];
            return (
              <StyledTableCell key={column.id}>
                <button
                  className="c-button btn-icon"
                  onClick={() => {
                    if (clipboard.isSupported()) {
                      clipboard.copy(value);
                    } else {
                     alert("Copy to clipboard not supported by browser.");
                    }
                  }}
                >
                  <img src={multipulFile} alt="multipul-file" />
                  Copy Template
                </button>
              </StyledTableCell>
            );
          } else if (column.id === "actions") {
            return (
              <StyledTableCell>
                <div className="o-icon">
                  <ActionButtonWithMenu
                    row={row}
                    onDeleteTemplateSubmit={onDeleteTemplateSubmit}
                    onEditTemplateSubmit={onEditTemplateSubmit}
                  />
                </div>
              </StyledTableCell>
            );
          } else if (column.id === "template_name") {
            return (
              <StyledTableCell component="th" scope="row">
                <div className="userinfo">
                  <img alt="profile" src={avatar} />
                  <span>
                    {column.format && typeof value === "number"
                      ? column.format(value)
                      : value}
                  </span>
                </div>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                className="text-blue"
                key={column.id}
                align={column.align}
              >
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </StyledTableCell>
            );
          }
        })}
      </StyledTableRow>
    );
  };

  let filteredData = !serverData.length ? false : serverData.smstemplateids.filter((row) => {
    let filterCriteria = namefilter;
    let filterText = row.template_name || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = senderfilter;
    filterText = row.template_senderid || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = contentfilter;
    filterText = row.template_text || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = labelfilter;
    filterText = JSON.stringify(row.tags) || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    return true;
  });

  let showTable =
    serverData &&
    serverData.smstemplateids &&
    serverData.smstemplateids.length > 0;

  return (
    <div>
      <Header name={"Sms Templates"} />
      <Induction
        name={props.name}
        content={sms_templates_links}
      />
      <AddTemplateButton onAddTemplateSubmit={onAddTemplateSubmit} />
      {!showTable ? (
        <div>
          <h3 style={{}}>
            You do not have any templates configured yet! Click on the button
            below to get started and add your first!
          </h3>
          <AddTemplateTableButton onAddTemplateSubmit={onAddTemplateSubmit} />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-header--with-icon card-head">
                <div className=" item-flex">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Name"
                      value={namefilter}
                      onChange={(event) => {
                        setNamefilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Sender"
                      value={senderfilter}
                      onChange={(event) => {
                        setSenderfilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Label"
                      value={labelfilter}
                      onChange={(event) => {
                        setLabelfilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Contents"
                      value={contentfilter}
                      onChange={(event) => {
                        setContentfilter(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="clear" />
              </div>
              <div className="card-body">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableRow id="table-head_rw">
                        {columns.map((column) => {
                          return (
                            <StyledTableCell
                              className="table-hd"
                              style={{ textAlign: column.align }}
                            >
                              {column.label}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((row) => {
                        return <SMSTableRow row={row} key={row.id} />;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
