import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import edit from "./assets/edit.svg";
import certificate from "./assets/certificate.svg";

import ToolTip from "@material-ui/core/ToolTip";

export default function CustomizedMenus(props) {
  const [open, setOpen] = React.useState(false);
  const [alias, setAlias] = React.useState("");
  const [host, setHost] = React.useState("");
  const [apikey, setApikey] = React.useState("");

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setAlias("");
    setHost("");
    setApikey("");
  };

  const handleDialogSubmit = () => {
    if (
      !alias ||
      alias.length === 0 ||
      !host ||
      host.length === 0 ||
      !apikey ||
      apikey.length === 0
    ) {
      alert("All fields are mandatory!");
      return;
    }
    setOpen(false);
    const editedObj = { ...props.row };
    editedObj.alias = alias;
    editedObj.host = host;
    editedObj.apikey = apikey;
    props.onEditTemplateSubmit(editedObj);
    setAlias("");
    setHost("");
    setApikey("");
  };

  return (
    <div style={{ display: "flex" }}>
      <ToolTip title="Edit Config">
        <img
          src={edit}
          alt="edit"
          onClick={() => {
            //logger.log("props.row", props.row);
            setAlias(props.row.alias);
            setHost(props.row.host);
            setApikey(props.row.apikey);
            handleDialogOpen();
          }}
        />
      </ToolTip>
      <ToolTip title="Delete Config">
        <img
          src={certificate}
          alt="certificate"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this config? This action is irreversible."
            );
            if (!result) {
              return
            }
            props.onDeleteTemplateSubmit(props.row);
          }}
        />
      </ToolTip>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Edit Active Campaign Config</div>
            <button
              type="button"
              className="close"
              onClick={handleDialogClose}
              style={{ marginLeft: "0px" }}
            >
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Alias</label>
              <div className="form-groups">
                <input
                  id="alias"
                  value={alias}
                  onChange={(event) => {
                    setAlias(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>

              <label>API Host</label>
              <div className="form-groups">
                <input
                  id="host"
                  value={host}
                  onChange={(event) => {
                    setHost(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>

              <label>API Key</label>
              <div className="form-groups">
                <input
                  id="apikey"
                  value={apikey}
                  onChange={(event) => {
                    setApikey(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleDialogClose}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDialogSubmit}
              className="c-button c-button_secondary"
            >
              Save
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
