exports.basepath        = 'http://localhost:3000'
exports.home            = '/'
exports.login           = '/login'
exports.forgotpassword  = '/forgotpassword'
exports.resetpassword   = '/resetpassword'
exports.signup          = '/signup'
exports.unsubscribe     = '/unsubscribe'
exports.admin           = '/admin'
exports.smstable        = this.admin + '/templates'
exports.history         = this.admin + '/history'
exports.campaignhistory = this.admin + '/campaignhistory'
exports.conversations   = this.admin + '/conversations'
exports.smsautomation   = this.admin + '/smsautomation'
exports.settings        = this.admin + '/settings'
exports.setupwizard     = this.admin + '/setup'
