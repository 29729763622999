import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CallIcon from "@material-ui/icons/Call";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import Constants from "./../../Utils/Constants";
import LoadingProgress from "./../../Common/LoadingProgress";
import Header from "./../../assets/components/header";
import Induction from "../Components/Induction";
import {sms_history_links} from "./../config/smshistory"

import "./assets/index.css";

const columns = [
  { id: "sentMessage", label: "Sent Message", minWidth: 170 },
  { id: "senderId", label: "Sender Id", minWidth: 170 },
  {
    id: "contactObj",
    label: "ActiveCampaign Contact",
    minWidth: 170,
    align: "left",
  },
  {
    id: "createTS",
    label: "Send Time",
    minWidth: 170,
    align: "center",
  },
];

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState([]);
  let interval = null;

  React.useEffect(() => {
    refreshData();
    interval = setInterval(() => {
      refreshData();
    }, 6000);
  }, []);

  const refreshData = () => {
    setServerData([])
  };

  if (!serverData) {
    return <LoadingProgress />;
  }

  return (
    <div>
      <Header name={"SMS Automation History"} />
      <Induction
        name={props.name}
        content={sms_history_links}
      />
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow id="table-head_rw">
                <StyledTableCell className="table-hd">
                  Sent Message
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  Sender ID
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  ActiveCampaign Contact
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  Send Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                serverData.length ?
              serverData.history.map((row) => {
                const rowId = row.id;
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "sentMessage") {
                        return (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            key={column.id}
                          >
                            <div className="speech-bubble">
                              <span>{value}</span>
                            </div>
                          </StyledTableCell>
                        );
                      } else if (column.id === "contactObj") {
                        let firstName, lastName, phone, email, params;
                        try {
                          params = JSON.parse(value);
                          firstName = params.contact_data.firstName;
                          lastName = params.contact_data.lastName;
                          phone = params.contact_data.phone;
                          email = params.contact_data.email;
                        } catch (e) {
                          params = new URLSearchParams(value);
                          firstName = params.get("contact[first_name]");
                          lastName = params.get("contact[last_name]");
                          phone = params.get("contact[phone]");
                          email = params.get("contact[email]");
                        }
                        return (
                          <StyledTableCell>
                            <div className="template-info">
                              <div className="template-info__item">
                                <PermIdentityIcon
                                  fontSize="small"
                                  style={{ fontSize: "0.9rem" }}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    marginLeft: "5px",
                                    color: "#000",
                                  }}
                                >
                                  {" "}
                                  {firstName + " " + lastName}{" "}
                                </span>
                              </div>

                              <div className="template-info__item">
                                <AlternateEmailIcon
                                  fontSize="small"
                                  style={{ fontSize: "0.7rem" }}
                                />
                                <span
                                  style={{ marginLeft: "5px", color: "#000" }}
                                >
                                  {" "}
                                  {email}{" "}
                                </span>
                              </div>

                              <div className="template-info__item">
                                <CallIcon
                                  fontSize="small"
                                  style={{ fontSize: "0.7rem" }}
                                />
                                <span
                                  style={{ marginLeft: "5px", color: "#000" }}
                                >
                                  {" "}
                                  {phone}{" "}
                                </span>
                              </div>
                            </div>
                          </StyledTableCell>
                        );
                      } else if (column.id === "createTS") {
                        return (
                          <StyledTableCell>
                            <div className="send-time">
                              <span>
                                {new Date(Number(value)).toLocaleString()}
                              </span>
                            </div>
                          </StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell className="text-blue">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );
              }) : ''
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
