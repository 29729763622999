import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [alias, setAlias] = React.useState("");
  const [host, setHost] = React.useState("");
  const [apikey, setApiKey] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlias("");
    setHost("");
    setApiKey("");
  };

  const handleSubmit = () => {
    if (
      !alias ||
      alias.length === 0 ||
      !host ||
      host.length === 0 ||
      !apikey ||
      apikey.length === 0
    ) {
      alert("All fields are mandatory!");
      return;
    }
    setOpen(false);
    props.addActiveCampaignConfig({ alias, host, apikey });
    setAlias("");
    setHost("");
    setApiKey("");
  };

  return (
    <div style={{ margin: "10px" }}>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={() => {
          handleClickOpen();
        }}
      >
        Add
      </span>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Add Active Campaign Config</div>
            <button
              type="button"
              className="close"
              onClick={handleClose}
              style={{ marginLeft: "0px" }}
            >
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Alias</label>
              <div className="form-groups">
                <input
                  id="alias"
                  value={alias}
                  onChange={(event) => {
                    setAlias(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>
              <label>API Host</label>
              <div className="form-groups">
                <input
                  id="host"
                  value={host}
                  onChange={(event) => {
                    setHost(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>
              <label>API Key</label>
              <div className="form-groups">
                <input
                  id="apikey"
                  value={apikey}
                  onChange={(event) => {
                    setApiKey(event.target.value);
                  }}
                  type="text"
                  className="form-controls"
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleClose}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="c-button c-button_secondary"
            >
              Add
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
