
export const sms_templates_links = [
  {
    text: "To Create an SMS Template",
    url: "https://www.youtube.com/embed/MNnTfkf0tFw"
  },
]

export const columns = [
  { id: "template_name", label: "Campaign Name", minWidth: 170, align: "left" },
  { id: "tags", label: "Labels", minWidth: 170, align: "center" },
  { id: "template_senderid", label: "Sender ID", minWidth: 170, align: "left" },
  { id: "template_text", label: "Template", minWidth: 170, align: "center" },
  { id: "link", label: "Link", minWidth: 100, align: "left", },
  { id: "actions", label: "", minWidth: 100, align: "center" },
];