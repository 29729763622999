import React from "react";
import Constants from "./../../../Utils/Constants"
import vybrntwhite from "./vybrnt-sms-logo.svg";
import { useNavigate } from "react-router-dom";

import "./index.scoped.css";

function HomeLogo(props) {
  const navigate = useNavigate();
  return (
    <div
      className="brand-name"
      onClick={() => {
        navigate(Constants.home);
      }}
    >
      <img className="white-logo" src={vybrntwhite}></img>
    </div>
  );
}

export default HomeLogo;
