import React, { useState } from "react";


export default function PolicyPrivacy(props) {

  
  return (
    <div>
        <object data="https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Privacy_Policy.pdf" 
                width="100%"
                height="500">
        </object>   
    </div>
  );

}