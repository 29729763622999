import Constants from "./../../Utils/Constants";

export const summary_promotion_boxes = [
  {
    title: "Templates",
    text: "Easily create SMS message copy, label them, and organise them for all your campaigns and nurture sequences (ActiveCampaign Automations).",
    click: Constants.smstable,
  },
  {
    title: "CAMPAIGN",
    text: "Easily Create SMS Campaigns. Send Promotion Out To A Synched ActiveCampaign List.",
    click: Constants.smsautomation,
  }
];

export const summary_induction_links = [
  {
    text: "To Create an SMS Template",
    url: "https://www.youtube.com/embed/MNnTfkf0tFw"
  },
  {
    text: "To Create an SMS Template",
    url: "https://www.youtube.com/embed/MNnTfkf0tFw"
  },
  {
    text: "To Create an SMS Template",
    url: "https://www.youtube.com/embed/MNnTfkf0tFw"
  },
  {
    text: "To Create an SMS Template",
    url: "https://www.youtube.com/embed/MNnTfkf0tFw"
  }
]