import React from "react";
import { withRouter } from "react-router-dom";
import vybrntfooter from "./vybrnt-blue-logo.svg";
import "./index.scoped.css";

function HomeLogo() {
  return (
    <div className="btm-creds">
      <p>Designed and developed by</p>
      <img src={vybrntfooter} alt="vybrnt footer logo" />
    </div>
  );
}

export default HomeLogo;
