import React from "react";

export default function(props) {
  const { open } = props;
  return (
    <div className="c-sidebar__brand" style={{ paddingTop: "20px" }}>
      {!open && (
        <img
          className="c-sidebar__logo--icon"
          alt="logo"
          src="https://sms-ui.vybrnt.com/static/media/V-SMS-vertical.216b96ed.svg"
          style={{ width: "30px", height: "36px" }}
        />
      )}
      {open && (
        <img
          className="c-sidebar__logo--icon"
          alt="logo"
          src="https://sms-ui.vybrnt.com/static/media/VYBRNT-SMS-horizontal-white.c531a245.svg"
          style={{ width: "100px", height: "36px" }}
        />
      )}
    </div>
  );
}
