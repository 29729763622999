import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Header from "../../assets/components/header";
import Icon from "../../assets/components/icon";
import Constants from "../../Utils/Constants";
import Induction from "../Components/Induction";
import Promotionbox from "../Components/promotionbox";

import FeedbackButton from "../Components/FeedbackButton";
import StrategyPortalButton from "./../Components/StrategyPortalButton";
import {summary_promotion_boxes, summary_induction_links} from "./../config/accountsummary"


import "./assets/index.css";

function Accountsummary(props) {
  return (
    <div>
      <Header name={"Dashboard"} />
      <FeedbackButton />
      <StrategyPortalButton />
      <img
        alt="logo"
        src="https://sms-ui.vybrnt.com/static/media/vybrnt-footer-logo.436821e9.svg"
        style={{ position: "fixed", bottom: "60px", right: "40px" }}
      />
      <Induction name={props.name} content={summary_induction_links} />
      <Grid container spacing={1} className="d-flex">
        {
          summary_promotion_boxes.map(
            summary_promotion_boxe => <Promotionbox 
                                          header={summary_promotion_boxe.title}
                                          text={summary_promotion_boxe.text} 
                                          click={summary_promotion_boxe.click} />
          )
        }
      </Grid>
    </div>
  );
}

export default Accountsummary;
