import React from 'react';

const Icon = ({ name, ...props }) => (<img
    {...props}
    src={require(`./../images/${name}.svg`)}
    alt={name}
  />
);

export default Icon;
