import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "./Backdrop";
export default function LoadingProgress() {
  return <Backdrop />;
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress color="primary" />
    </div>
  );
}
