import React from "react";
import Dialog from "@material-ui/core/Dialog";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <li>
      <p>
        {props.text} {' '}
        <a
          style={{ fontWeight: "bold" }}
          href={props.url}
          onClick={(event) => {
            event.preventDefault();
            handleClickOpen();
          }}
        >
          CLICK HERE 
        </a>
      </p>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <iframe 
          width="960" 
          height="600"
          src={props.url}
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen>
          </iframe>
      </Dialog>
    </li>
  );
}
