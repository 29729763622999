import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import validator from "validator";
import LoadingProgress from "./../../../../Common/LoadingProgress";

function SimpleDialog(props) {
  const { templatetext, senderId } = props;

  const { onClose, open } = props;
  const [phoneNo, setPhoneNo] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");

  const handleClose = () => {
    onClose();
  };

  const handlePlanSelection = async () => {
    if (!phoneNo || phoneNo.length === 0) {
      alert("Please enter a phone no.");
      return;
    }
    const isValidPhoneNumber = validator.isMobilePhone(phoneNo);
    if (!isValidPhoneNumber) {
      alert("Enter a valid phone no.");
      return;
    }
    setIsLoading(true);
    setIsLoading(false);
  };

  let dialogCOntent = (
    <div>
      <DialogTitle id="simple-dialog-title">
        Enter test phone number to send SMS
      </DialogTitle>
      <DialogContent>
        <div className="form-groups">
          <input
            id="campaignName"
            value={phoneNo}
            onChange={(event) => {
              setPhoneNo(event.target.value);
            }}
            type="text"
            className="form-controls"
          />
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px 24px" }}>
        <span
          className="c-button text-uppercase"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          Cancel
        </span>
        <span
          className="c-button c-button--secondary text-uppercase"
          onClick={handlePlanSelection}
        >
          Send Test SMS
        </span>
      </DialogActions>
    </div>
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {dialogCOntent}
      </Dialog>
    </>
  );
}

export default function SimpleDialogDemo({ templatetext, senderId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (
      senderId &&
      senderId.length > 0 &&
      templatetext &&
      templatetext.length > 0
    ) {
      setOpen(true);
    } else {
      alert("Please ensure SenderId and Template Text are filled");
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
      >
        Send a Test
      </span>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        templatetext={templatetext}
        senderId={senderId}
      />
    </React.Fragment>
  );
}
