import React, { useState } from "react";


export default function TwoWaysSms(props) {

  
  return (
    <div>
        <b>61437115260</b><br />
        https://api.vybrnt.com/SLInterActiveCampaignMiddleware/api/v1/twowayincomingsms/e8b1b2a4-36db-4826-a033-92f494e570cc
    </div>
  );

}