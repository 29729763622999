import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Icon from "../../assets/components/icon";

export default function Promotionbox({header, text, click}) {
  
  const navigate = useNavigate();

  return (
    <Grid item xs={3} className="zoom d-flex w-25" style={{ flexBasis: "23%" }}>
      <div className="sms-auto_tab w-100">
        <h2>SMS</h2>
        <h2>{header}</h2>
        <p>
          {text}
        </p>
        <Icon
          className="zoombutton"
          name="send-mail"
          onClick={ () => navigate(click) }
        />
        <div className="clear"></div>
      </div>
    </Grid>
  );
}

