import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom"; 

import "./../assets/index.css";

function Drawerbuilder({drawerelements, selected, setSelected }) {

  const classNameActive   = "c-sidebar__nav-link--active";
  const classNameInactive = "c-sidebar__nav-link";

  const drawer = (
    <div>
      <List style={{ color: "white", fontWeight: "900" }}>
        {drawerelements.map((item, index) => {
          if (item.condition && !item.condition()) {
            return null;
          }
          if (item.type === "divider") {
            return <Divider key={"dividerAt" + index} />;
          } else if (item.type === "button") {
            let className = classNameInactive;
            return (
              <Tooltip title={item.title} key={index}>
                <ListItem
                  button
                  key={item.title}
                  onClick={item.onClick}
                  className={className}
                >
                  <ListItemIcon style={{ color: "white" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Tooltip>
            );
          } else if (item.type === "link") {
            let className = classNameInactive;
            if (item.route === selected) {
              className = classNameActive;
            }
            return (
              <Tooltip title={item.title}  key={index}>
                <ListItem
                  button
                  key={item.route}
                  component={Link}
                  to={item.route}
                  className={className}
                  onClick={() => {
                    setSelected(item.route);
                  }}
                >
                  <ListItemIcon style={{ color: "white" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Tooltip>
            );
          }
        })}
      </List>
    </div>
  );

  return drawer;

}

export default Drawerbuilder;
