import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Constants from "./../../Utils/Constants";
import Typography from "@material-ui/core/Typography";
import CampaignSMSDetailedHistory from "./CampaignSMSDetailedHistory";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Induction from "../Components/Induction";
import {sms_campaign_history_links} from "./../config/smscampaignhistory"

import LoadingProgress from "./../../Common/LoadingProgress";

import Header from "./../../assets/components/header";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const columns = [
  {
    id: "campaignName",
    label: "Campaign Name [ActiveCampaign Tag]",
    minWidth: 70,
  },
  { id: "campaignDescription", label: "Description", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 50 },
  {
    id: "createTs",
    label: "Send Time",
    minWidth: 170,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const CHARACTER_LIMIT = 160;

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState([]);
  let interval = null;

  React.useEffect(() => {
    
  }, []);


  if (!serverData) {
    return <LoadingProgress />;
  }

  const CampaignHistoryRow = (props) => {
    const row = props.row;
    const rowId = row.id;
    const [show, setShow] = React.useState(false);

    const getData = () => {
      return []
    };

    return (
      <React.Fragment>
        <StyledTableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={rowId}
          onClick={() => {
            const opposite = !show;
            setShow(opposite);
            if (opposite === true) {
              getData();
            }
          }}
        >
          {columns.map((column) => {
            const value = row[column.id];
            if (column.id === "createTs") {
              return (
                <StyledTableCell>
                  <div className="send-time">
                    <span>{new Date(Number(value)).toLocaleString()}</span>
                  </div>
                </StyledTableCell>
              );
            } else {
              return (
                <StyledTableCell>
                  <div className="send-time">
                    <span>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </span>
                  </div>
                </StyledTableCell>
              );
            }
          })}
        </StyledTableRow>
        {show && serverData && (
          <ExpansionPanelDetails style={{ display: "block" }}>
            <div style={{ padding: "10px" }}>
              <Typography gutterBottom variant="h5" component="h2">
                {serverData.selectedenv.alias}
              </Typography>
              <Typography style={{ color: "grey" }}>
                {serverData.selectedenv.host}
              </Typography>
              <a
                href={
                  serverData.selectedenv.host +
                  "/app/contacts/?listid=" +
                  serverData.selectedlist.id +
                  "&status=1"
                }
                target="_blank"
              >
                <Typography style={{ color: "grey" }}>
                  {serverData.selectedlist.name}
                </Typography>
              </a>
              <Typography style={{ color: "grey" }}>
                Total SMS Count: {serverData.selectedlistcontacts.length}
              </Typography>
              <Typography style={{ color: "grey" }}>
                SMS Sender: {serverData.sms_senderid}
              </Typography>
              <CampaignSMSDetailedHistory
                campaignId={row.id}
                selectedlistcontacts={serverData.selectedlistcontacts}
                sms_senderid={serverData.sms_senderid}
                sms_template={serverData.sms_template}
              />
            </div>
          </ExpansionPanelDetails>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <Header name={"SMS Campaign History"} />
      <Induction
        name={props.name}
        content={sms_campaign_history_links}
      />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow id="table-head_rw">
                {columns.map((column) => (
                  <StyledTableCell className="table-hd">
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {serverData.campaigns &&
                serverData.campaigns.map((row) => (
                  <CampaignHistoryRow row={row} key={row.id} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
