import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import Constants from "./Utils/Constants";
import Home from "./Site/Home";
import Login from "./Site/Login";
import Signup from "./Site/Signup";
import ForgotPassword from "./Site/ForgotPassword";
import ResetPassword from "./Site/ResetPassword";
import Admin from "./Admin";

import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <div className="content">
          <Routes>
              {/* <Route path={Constants.contact} element={<Home />} />
              <Route path={Constants.home} element={<Home />} /> */}
              <Route exact path={Constants.contact} element={<Login />} />
              <Route exact path={Constants.home} element={<Login />} />
              <Route exact path={Constants.login} element={<Login />} />
              <Route exact path={Constants.signup} element={<Signup />} />
              <Route exact path={Constants.forgotpassword} element={<ForgotPassword />} />
              <Route exact path={Constants.resetpassword} element={<ResetPassword />} />
              <Route path={Constants.admin + '/*'} element={<Admin />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
