import React from "react";
import TagMenu from "./TagMenu";
import Chip from "@material-ui/core/Chip";

/*
import TagSection from "Common/TagSection";
<TagSection
                baseUrl={apiPath + "/api/moviesorter"}
                tags={values.tags}
                setTags={(tags) => {
                  addValues({
                    tags,
                  });
                }}
              />
*/

export default function TagSection(props) {
  const { templateId } = props;

  const [tags, setTags] = React.useState(props.row.tags);

  return (
    <div style={{}}>
      <TagMenu
        selected={tags}
        addTagToMovie={(tag) => {
          
        }}
        addTagToTagSet={() => {
          
        }}
        tagSet={[]}
      />
      <div style={{ textAlign: "center" }}>
        {tags &&
          tags.map((value) => (
            <Chip
              key={value}
              label={value}
              style={{ margin: "2px" }}
              onDelete={(e) => {}}
            />
          ))}
      </div>
    </div>
  );
}
