import React, { useEffect } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom"; 
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Constants from "./../Utils/Constants"
import useStyles from "./config/drawerconfig";
import DrawerLogo from "./DrawerLogo";
import Componentswitcher from "./Components/componentswitcher";
import { drawerelements } from "./config/drawerelements"
import Drawerbuilder from "./Components/drawerbuilder"
import "./assets/index.css";
import Icon from "./../assets/components/icon"

function Admin(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(Constants.admin);
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname
  const [menu, setMenu] = React.useState(drawerelements)

  const user = {
    firstname: 'John',
    id: 1
  }

  const toggleDrawerOpenClose = () => {
    setOpen(!open);
  };

  Constants.navigate = url => navigate(url);

  useEffect(() => {
    setSelected(location.pathname)
    setMenu(location.pathname == Constants.setupwizard ? [] : drawerelements)
  }, [location]);

  return (
    <div className={classes.root}> 
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          style={{ borderRight: "none" }}
        >
          <DrawerLogo open={open} />
          <div className={classes.toolbar}>
            <div style={{ position: "relative", left: "-10px" }}>
              <div style={{ position: "absolute" }}>
                <button
                  className={ open ? "c-header__toggle-btn" : "c-header__toggle-btn--show" }
                  onClick={toggleDrawerOpenClose}
                  type="button"
                  style={{
                    position: "fixed",
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    top: "60px",
                  }}
                >
                  <Icon name="hide-menu" />
                </button>
              </div>
            </div>
          </div>
          <Divider />
          <Drawerbuilder 
             drawerelements={menu}
             selected={selected}
             setSelected={setSelected}
            />
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <Componentswitcher path={currentLocation} user={user} />
        </main>
    </div>
  );
}

export default Admin;
