import React from 'react';
import "./header.css"
// import Credits from "../../Drawer/Credits"
import CreditsButtonForm from "../../Admin/Credits/CreditsButtonForm"


const Header = ({ name }) => (
  <header className="c-header">
   <h3 className="text-uppercase m-0">{name}</h3>
   <div className="c-header__item">
     <span className="c-button text-uppercase">Upgrade plan</span>
     <CreditsButtonForm />
   </div>
 </header>
);

export default Header;
