import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "./../../../Utils/Constants"

const ActiveCampaignForm = () => {
  let count = 1
  const create_script = () => {
    let is_available = document.getElementById("ac_embed_script")
    if(is_available == null) {
      const script = document.createElement("script");
      script.id="ac_embed_script"
      script.src = "https://vybrnt.activehosted.com/f/embed.php?id=71";
      script.async = true;
      document.body.appendChild(script);
    } else {
      is_available.remove()
      const script = document.createElement("script");
      script.id="ac_embed_script"
      script.src = "https://vybrnt.activehosted.com/f/embed.php?id=71";
      script.async = true;
      document.body.appendChild(script);
    }
  }
  React.useEffect(() => {
    if(count == 1)
      create_script()
    count++
  }, [])

  return <div class="_form_71" ></div>
  
}

export default function CreditsButtonForm(props) {
  const [open, setOpen] = React.useState(false);
  const [credits, setCredits] = React.useState(0);

  Constants.openCredits = () => setOpen(true);

  const getCredits = () => {  };

  let timer;

  React.useEffect(() => {
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    let is_available = document.getElementById("ac_embed_script")
    is_available.remove()
    setOpen(false);

  };

  return (
    <>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
      >
        Credits : {credits}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="id894567">
          <ActiveCampaignForm />
        </DialogContent>
      </Dialog>
    </>
  );
}
