import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Constants from "./../../Utils/Constants";

import HomeLogo from "./../Components/HomeLogo";
import HomeFooter from "./../Components/HomeFooter";
import FeatureGrid from "./../Components/FeatureGrid";
import { Link } from "react-router-dom";

import "./index.scoped.css";

function ForgotPassword(props) {
  const username = "";
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid />
          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo />

              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">forgot password</h3>
                <div style={{ color: "white" }}>
                  Please enter your account email below and click on "Reset
                  password" button.
                  <br />
                  You will receive further instructions to reset password on
                  that email.
                </div>
                <br />
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Email"
                    className="login-textbx"
                    {...username}
                    type="text"
                    id="email"
                    name="email"
                    value={username.value}
                  />
                </div>

                <div className="action-link">
                  <Link
                    className="forgot-link terms "
                    to={Constants.resetpassword}
                    style={{ textDecoration: "underline" }}
                  >
                    Already got a reset token? Enter here.
                  </Link>
                  <Link
                    className="forgot-link"
                    to={Constants.login}
                    style={{ textDecoration: "underline" }}
                  >
                    Go back to login.
                  </Link>

                  <input
                    className="login-btn c-button m-0"
                    type="button"
                    value="SEND OTP"
                    onClick={handleLogin}
                    disabled={loading}
                  />
                </div>
              </div>
              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );

}

export default ForgotPassword;
