import React from "react";
import Paper from "@material-ui/core/Paper";
import Header from "./../../assets/components/header";
import Induction from "../Components/Induction";
import CXAppApiKeysSection from "./CXAppApiKeysSection";
import TwoWaySMSSection from "./TwoWaySMSSection";
import UnsubscribeCode from "./UnsubscribeCode";
import ACConfigsSection from "./ACConfigsSection";
import LegalCenter from "./LegalCenter";
import { sms_settings_links } from "./../config/settings"

export default function StickyHeadTable(props) {
  return (
    <div>
      <Header name={"Settings"} />
      <Induction
        name={props.name}
        content={ sms_settings_links }
      />

      <Paper style={{ margin: "1 auto", maxWidth: "90%" }}>
        <ACConfigsSection />
      </Paper>
      <br />
      <Paper style={{ margin: "1 auto", maxWidth: "90%" }}>
        <LegalCenter />
      </Paper>
      <br />
      <Paper style={{ margin: "1 auto", maxWidth: "90%" }}>
        <CXAppApiKeysSection />
      </Paper>
      <br />
      <Paper style={{ margin: "1 auto", maxWidth: "90%" }}>
        <TwoWaySMSSection />
      </Paper>
      <br />
      <Paper style={{ margin: "1 auto", maxWidth: "90%" }}>
        <UnsubscribeCode />
      </Paper>
    </div>
  );
}
