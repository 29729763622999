import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "./../../../../Utils/Constants";
import LoadingProgress from "./../../../../Common/LoadingProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const CHARACTER_LIMIT = 160;

function SimpleDialog(props) {
  const { onTemplateSelected } = props;

  const { onClose, open } = props;
  const [serverData, setServerData] = []
  const [isLoading, setIsLoading] = React.useState(false);

  const refreshData = () => {
    setIsLoading(true);
  };

  React.useEffect(() => {
    if (open === true) {
      refreshData();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = async (item) => {
    onTemplateSelected(item);
    handleClose();
  };

  let dialogCOntent = (
    <div>
      <DialogTitle id="simple-dialog-title">Select a Template</DialogTitle>
      <DialogContent>
        <List
          aria-label="secondary mailbox folder"
          style={{ overflow: "auto" }}
        >
          {serverData?.smstemplateids?.map((item, index) => {
            return (
              <ListItem
                key={"templateSelect_" + item.id}
                button
                onClick={(event) => handleListItemClick(item)}
              >
                <div className="template-info">
                  <div className="template-info__item">
                    <p>{item.template_name}</p>
                  </div>
                  <div className="template-info__item">
                    <p className="template-info__item--border">
                      {item.template_text}
                    </p>
                  </div>
                  <div className="template-info__item">
                    <p>
                      {item.template_text?.length} / {CHARACTER_LIMIT}
                    </p>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px 24px" }}>
        <span
          className="c-button text-uppercase"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          Cancel
        </span>
      </DialogActions>
    </div>
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        scroll={"body"}
      >
        {dialogCOntent}
      </Dialog>
    </>
  );
}

export default function SimpleDialogDemo({ onTemplateSelected }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
        style={{ marginRight: "20px" }}
      >
        Import Template
      </span>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onTemplateSelected={onTemplateSelected}
      />
    </React.Fragment>
  );
}
