import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Constants from "./../../Utils/Constants";
import FeatureGrid from "./../Components/FeatureGrid";
import HomeLogo from "./../Components/HomeLogo";
import HomeFooter from "./../Components/HomeFooter";

import "./assets/index.css";
import { Link } from "react-router-dom";

function ResetPassword(props) {
  const resettoken = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid />
          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo />

              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">
                  Enter Secure Token for Password reset
                </h3>
                <div style={{ color: "white" }}>
                  If you have triggered a password reset, please check your
                  email for the secure password reset token.
                  <br />
                  Please enter the token below and the new password to reset
                  your password.
                </div>
                <br />
                <div className="form-group" style={{ width: "100%" }}>
                  <div className="form-group" style={{ width: "100%" }}>
                    <input
                      autoFocus
                      className="login-textbx"
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Secure Token"
                      {...resettoken}
                      required={true}
                    />
                  </div>
                  <div className="form-group" style={{ width: "100%" }}>
                    <input
                      id="password"
                      className="login-textbx"
                      name="password"
                      placeholder="New Password"
                      type="password"
                      {...password}
                      required={true}
                      onKeyPress={(event) => {
                        if (event.key == "Enter") {
                          handleLogin();
                        }
                      }}
                    />
                  </div>
                  <div>
                    {error && (
                      <>
                        <small style={{ color: "red" }}>{error}</small>
                        <br />
                      </>
                    )}
                  </div>
                </div>

                <div className="action-link">
                  <Link
                    className="forgot-link"
                    to={Constants.forgotpassword}
                    style={{ textDecoration: "underline" }}
                  >
                    Dont have a reset token? Generate here.
                  </Link>
                  <Link
                    className="forgot-link"
                    to={Constants.login}
                    style={{ textDecoration: "underline" }}
                  >
                    Go back to login.
                  </Link>

                  <input
                    className="login-btn c-button m-0"
                    type="button"
                    value="RESET PASSWORD"
                    onClick={handleLogin}
                    disabled={loading}
                  />
                </div>
              </div>
              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default ResetPassword;
