import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import Header from "../../assets/components/header";
import ActiveCampiagn from "./activecampaign";
import CxApp from "./cx-app"
import PolicyAntispam from "./policy-antispam";
import PolicyPrivacy from "./policy-privacy";
import TwoWaysSms from "./two-ways-sms";
import UnsubscribeCode from "./unsubscribe-code";
import Constants from "./../../Utils/Constants";
import "./assets/index.css";

export default function SetupWizard(props) {

  const navigate = useNavigate();

  const [steps, setSteps] = useState([
    { index: 1, key: 'firstStep', label: 'Active Campaign', isDone: true, component: ActiveCampiagn },
    { index: 2, key: 'secondStep', label: 'Antispam Policy', isDone: false, component: PolicyAntispam },
    { index: 3, key: 'thirdStep', label: 'Privacy Policy', isDone: false, component: PolicyPrivacy },
    { index: 4, key: 'fourthStep', label: 'API Key', isDone: false, component: CxApp },
    { index: 5, key: 'fifthStep', label: 'Two Ways SMS', isDone: false, component: TwoWaysSms },
    { index: 6, key: 'finalStep', label: 'Congratulation', isDone: false, component: UnsubscribeCode },
  ])
 
  const [activeStep, setActiveStep] = useState(steps[0]);
 
  const handleNext = () => {
    if (steps[steps.length - 1].key === activeStep.key) {
      navigate(Constants.admin)
      return;
    }
  
    const index = steps.findIndex(x => x.key === activeStep.key);
    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }))
    setActiveStep(steps[index + 1]);
  }
  
  const handleBack = () => {
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;
  
    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }))
    setActiveStep(steps[index - 1]);
  }
 
  return (
      <div>
      <Header name={`Setup (${activeStep.index + '/' + steps.length})`} />
      <div className="box">
        <div className="steps">
          <ul className="nav">
            {steps.map((step, i) => {
              return <li key={i} className={`${activeStep.key === step.key ? 'active' : ''} ${step.isDone ? 'done' : ''}`}>
                <div>Step {i + 1}<br /><span>{step.label}</span></div>
              </li>
            })}
          </ul>
        </div>
        <div className="step-component">
            <activeStep.component name={props.name}/>
        </div>
        <div className="btn-component">
          <input type="button" value="Back" onClick={handleBack} disabled={steps[0].key === activeStep.key} className="app-btn btn" />
          <input type="button" value={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} onClick={handleNext} className="app-btn btn" />
        </div>
      </div>
    </div>
  );

}

