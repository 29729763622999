import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import { getThemeProps } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SelectedListItem(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index, listitem) => {
    setSelectedIndex(index);
    props.setSelectedList(listitem)
  };

  return (
    <div className={classes.root}>
      <List aria-label="secondary mailbox folder">
        {props.lists &&
          props.lists.map((listitem, index) => {
            return (
              <ListItem
                key={listitem.id}
                button
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index, listitem)}
              >
                <ListItemText primary={listitem.name} />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
