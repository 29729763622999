import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Constants from "./../../Utils/Constants" 

import HomeLogo from "./../Components/HomeLogo"
import HomeFooter from "./../Components/HomeFooter"
import FeatureGrid from "./../Components/FeatureGrid";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";

import "./assets/index.scoped.css";

function Signup(props) {
  const firstname = useFormInput("");
  const lastname = useFormInput("");
  const account = useFormInput("");
  const contactno = useFormInput("");
  const country = useFormInput("");
  const postcode = useFormInput("");
  const username = useFormInput("");
  const password = useFormInput("");
  const reenterpassword = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // handle button click of login form
  const handleSignup = () => {
    navigate(Constants.setupwizard)
    if (password.value !== reenterpassword.value) {
      //throw exception that passwords dont match
      alert("Password fields do not match. Please re enter.");
      return;
    }

    const requiredFields = [
      firstname,
      lastname,
      contactno,
      country,
      postcode,
      username,
      password,
    ];
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (!field || !field.value || field.value.length == 0) {
        alert("Please enter all required fields.");
        return;
      }
    }
    if (!state.checkedB) {
      alert("Please accept terms and conditions to proceed.");
      return;
    }
    setError(null);
    setLoading(true);
  };

  const [state, setState] = React.useState({ checkedB: false });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const TermsAndConditions = (props) => {
    return (
        <span className="terms" style={{color:'#fff'}}>
          *By using the VYBRNT SMS platform, you consent to our legal terms and
          policies.{" "}
          <a
            id="info_link"
            href="https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Anti_Spam_Policy.pdf"
            download
            onClick={ e => {
              e.preventDefault()
              window.open("https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Anti_Spam_Policy.pdf")
              window.open("https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Privacy_Policy.pdf")
            }}
          >
            See here for more information
          </a>
        </span>
    );
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid />

          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo />

              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">Sign Up</h3>

                <div className="form">
                  <div className="form-group">
                    <input
                      placeholder="First Name*"
                      className="login-textbx"
                      type="text"
                      id="firstname"
                      label="First Name"
                      {...firstname}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Last Name*"
                      className="login-textbx"
                      type="text"
                      id="lastname"
                      label="Last Name"
                      {...lastname}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Account (Company)*"
                      className="login-textbx"
                      id="account"
                      label="Account(Company)"
                      {...account}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Phone Number*"
                      className="login-textbx"
                      type="text"
                      id="contactno"
                      label="Phone Number"
                      {...contactno}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Country*"
                      className="login-textbx"
                      type="text"
                      id="country"
                      label="Country"
                      {...country}
                    />
                  </div>
                </div>

                <div className="form">
                  <div className="form-group">
                    <input
                      placeholder="Postcode/Zipcode*"
                      className="login-textbx"
                      type="text"
                      id="postcode"
                      label="Postcode/Zipcode"
                      {...postcode}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Email*"
                      className="login-textbx"
                      type="text"
                      id="email"
                      label="Email"
                      {...username}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Password*"
                      className="login-textbx"
                      type="password"
                      id="password"
                      label="Password"
                      {...password}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Reenter Password*"
                      className="login-textbx"
                      type="password"
                      id="reenterpassword"
                      label="Reenter Password"
                      {...reenterpassword}
                    />
                  </div>
                </div>

                <div>
                  {error && (
                    <>
                      <small style={{ color: "red" }}>{error}</small>
                      <br />
                    </>
                  )}
                </div>
              </div>

              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        style={{ color: "white" }}
                      />
                    }
                    label={<TermsAndConditions/>}
                  />
                </div>

                <div class="form">
                  <Link
                    className="forgot-link"
                    to={Constants.login}
                    style={{ textDecoration: "underline" }}
                  >
                    Already have an account? Sign in.
                  </Link>
                </div>

                <div class="form">
                  <input
                    className="login-btn c-button pull-right"
                    type="button"
                    value="SIGN UP"
                    onClick={handleSignup}
                    disabled={loading}
                  />
                </div>
              </div>

              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Signup;
