import React from "react";

import weblayout from "./../../../assets/images/a-girl-with-laptop.png";
import QuickStartVideoDialog from "./../QuickStartVideoDialog";

import "./assets/index.css";

export default function Induction(props) {
  const [openPanel, setOpenPanel] = React.useState(true)

  if (props.content) {
    return (
      <div className="c_layout-welcome">
        <div className="row">
          <div  className={!openPanel ? "col-12 text-center" : "d-none" }> 
            <h3 className="font-weight-bold text-uppercase ">
              Welcome {props.name ? props.name : ""} 
                <a href="#" className="float-right" onClick={e => {e.preventDefault(); setOpenPanel(!openPanel)}}> 
                  { openPanel ? 'X' : 'V'} 
                </a>
            </h3>
          </div>
        </div>
        <div className={openPanel ? "row" : "d-none"}>
          <div className="col-3"> 
            <img src={weblayout} alt="Automation" style={{ width: "250px" }} />
          </div>

          <div className="col-9"> 
            <h3 className="font-weight-bold text-uppercase">
              Welcome {props.name ? props.name : ""} 
                <a href="#" className="float-right" onClick={e => {e.preventDefault(); setOpenPanel(!openPanel)}}> 
                  { openPanel ? 'X' : 'V'} 
                </a>
            </h3>
            <ul style={{'list-style-type': 'circle'}}>
              {props.content.map(c =>  <QuickStartVideoDialog url={c.url} text={c.text} />)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
