import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ToolTip from "@material-ui/core/ToolTip";

import "./assets/index.css";

const CHARACTER_LIMIT = 160;

const customContentStyle = {
  width: "100%",
};

export default function AddTemplateTableButton(props) {
  const [open, setOpen] = React.useState(false);
  const [templatetext, setTemplatetext] = React.useState(
    "Type your SMS message here.\nFor marketing message compliance, you can use the optout hyperlink at the end of this message as it is. (https://bit.ly/3slAgSv)"
  );
  const [name, setName] = React.useState("");
  const [senderId, setSenderId] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    //logger.log("Open is:" + open);
  };

  const handleClose = () => {
    setOpen(false);
    setTemplatetext("");
    setName("");
    setSenderId("");
  };

  const handleSubmit = () => {
    if (
      !templatetext ||
      templatetext.length === 0 ||
      !name ||
      name.length === 0 ||
      !senderId ||
      senderId.length === 0
    ) {
      alert("All fields are mandatory!");
      return;
    }
    if (senderId.length > 11) {
      alert("Sender Id should be less than or equal to 11 characters.");
      return;
    }
    setOpen(false);
    props.onAddTemplateSubmit({
      template_text: templatetext,
      template_name: name,
      template_senderid: senderId,
    });
    setTemplatetext("");
    setName("");
    setSenderId("");
  };

  return (
    <div>
      <ToolTip title="Add new SMS Template">
        <div id="wrap-btn">
          <input
            id="plan-btn"
            type="button"
            value="Add a template"
            onClick={handleClickOpen}
          />
        </div>
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Add SMS Template</div>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Name</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formName"
                  className="form-controls"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>

              <label>Sender Id</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formSenderId"
                  className="form-controls"
                  value={senderId}
                  onChange={(event) => {
                    setSenderId(event.target.value);
                  }}
                />
              </div>

              <label>SMS Template</label>
              <div className="form-groups">
                <textarea
                  rows="4"
                  id="formSmsTemplate"
                  className="form-controls"
                  value={templatetext}
                  onChange={(event) => {
                    setTemplatetext(event.target.value);
                  }}
                  inputProps={{
                    maxLength: CHARACTER_LIMIT,
                  }}
                  helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
                />
              </div>
              <div className="text-limit">{`${
                templatetext.length
              }/${CHARACTER_LIMIT}`}</div>
            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleClose}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="c-button c-button_secondary"
            >
              Add
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
