import React from "react";
import SendTestDialog from "./SendTestDialog";
import ImportFromTemplates from "./ImportFromTemplates";

import Typography from "@material-ui/core/Typography";

const CHARACTER_LIMIT = 160;

export default function BulkSMSForm(props) {
  const [templatetext, setTemplatetext] = React.useState(
    "Type your SMS message here.\nFor marketing message compliance, you can use the optout hyperlink at the end of this message as it is. (https://bit.ly/3slAgSv)"
  );
  const [senderId, setSenderId] = React.useState("");

  const setSenderIdOnParent = (value) => {
    setSenderId(value);
    const bulkSMSConfig = {
      senderId: value,
      template: templatetext,
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const setTemplateOnParent = (value) => {
    setTemplatetext(value);
    const bulkSMSConfig = {
      senderId: senderId,
      template: value,
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  return (
    <div>
      <form>
        {!props.useTwoWayNumber && (
          <>
            <label>Sender Id*</label>
            <div className="form-groups">
              <input
                id="senderId"
                value={senderId}
                onChange={(event) => {
                  setSenderIdOnParent(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </div>
          </>
        )}
        <label>SMS Template*</label>
        <div className="form-groups">
          <textarea
            rows="6"
            id="template"
            className="form-controls"
            value={templatetext}
            onChange={(event) => {
              setTemplateOnParent(event.target.value);
            }}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
            placeholder={
              "Example:\nHi %FIRSTNAME% %LASTNAME%,\nPlease respond to your email on %EMAIL%.\nThanks!"
            }
          />
        </div>
        <div className="text-limit">{`${
          templatetext.length
        }/${CHARACTER_LIMIT}`}</div>
      </form>
      <Typography style={{ color: "red" }}>
        *If your message is above 160 characters, it will charge for two SMS per
        person.
      </Typography>
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <div
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            textAlign: "end",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ImportFromTemplates
            onTemplateSelected={(template) => {
              setTemplateOnParent(template?.template_text);
            }}
          />
          <SendTestDialog templatetext={templatetext} senderId={senderId} />
        </div>
      </div>
    </div>
  );
}
