import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Link, useNavigate } from "react-router-dom";

import Constants from "./../../Utils/Constants";
import HomeLogo from "./../Components/HomeLogo"  
import HomeFooter from "./../Components/HomeFooter";
import FeatureGrid from "./../Components/FeatureGrid";

import "./assets/index.scoped.css";

function Login(props) {
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    navigate(Constants.admin)
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid />
          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo />
              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">login</h3>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Email"
                    className="login-textbx"
                    {...username}
                    type="text"
                    id="email"
                    name="email"
                    value={username.value}
                  />
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Password"
                    className="login-textbx"
                    {...password}
                    type="password"
                    value={password.value}
                    id="password"
                    name="password"
                    onKeyPress={(event) => {
                      if (event.key == "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                </div>
                <div>
                  {error && (
                    <>
                      <small style={{ color: "red" }}>{error}</small>
                      <br />
                    </>
                  )}
                </div>
                <div className="action-link d-flex">
                  <Link
                    className="forgot-link"
                    to={Constants.forgotpassword}
                  >
                    Forgot Password?
                  </Link>

                  <Link
                    className="forgot-link"
                    to={Constants.signup}
                    style={{ textDecoration: "underline" }}
                  >
                    Don't have an account? Create one.
                  </Link>
         
                  <input
                    className="login-btn c-button"
                    type="button"
                    value="SIGN IN"
                    onClick={handleLogin}
                    disabled={loading}
                  />
                </div>
              </div>
              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
