import Constants from "./../../Utils/Constants"

import HistoryIcon from "@material-ui/icons/History";
import SmsIcon from "@material-ui/icons/Sms";
import MailIcon from "@material-ui/icons/Mail";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HomeIcon from "@material-ui/icons/Home";
import TimelineIcon from "@material-ui/icons/Timeline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"; 

export const drawerelements = [
  {
    title: "Dashboard",
    type: "link",
    icon: <HomeIcon />,
    route: Constants.admin,
  },
  {
    type: "divider",
  },
  {
    title: "SMS Templates",
    type: "link",
    icon: <SmsIcon />,
    route: Constants.smstable,
  },
  {
    title: "SMS Campaign",
    type: "link",
    icon: <MailIcon />,
    route: Constants.smsautomation,
  },
  {
    title: "Automation History",
    type: "link",
    icon: <HistoryIcon />,
    route: Constants.history,
  },
  {
    title: "Campaign History",
    type: "link",
    icon: <TimelineIcon />,
    route: Constants.campaignhistory,
  },
  {
    title: "Conversations",
    type: "link",
    icon: <QuestionAnswerIcon />,
    route: Constants.conversations
  },
  {
    type: "divider",
  },
  {
    title: "Account",
    type: "button",
    icon: <AttachMoneyIcon />,
    onClick: () => {
      Constants.openCredits();
    },
  },
  {
    title: "Settings",
    type: "link",
    icon: <SettingsIcon />,
    route: Constants.settings,
  },
  {
    title: "Logout",
    type: "button",
    icon: <ExitToAppIcon />,
    onClick: () => {
      Constants.navigate(Constants.login)
    },
  },
];