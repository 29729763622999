import React, { useState } from "react";


export default function UnsubscribeCode(props) {

  
  return (
    <div>
        unsubscribe-code: <b>Cbpsm</b><br />
        https://sms-ui.vybrnt.com/ui/unsubscribe/Cbpsm <br />
        <i>*You can copy the above link into a url shortener like bit.ly and include it in the SMS body.</i>
    </div>
  );

}