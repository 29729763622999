import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingProgress from "./../../../Common/LoadingProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function ControlledExpansionPanels() {
  const user = {
    virtualNumbers: []
  };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [virtualNumbers, setVirtualNumbers] = React.useState(
    user.virtualNumbers
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  if (!user.virtualNumbers) {
    return null;
  }

  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>Two Way SMS Numbers</Typography>
        <Typography className={classes.secondaryHeading}>
          View configured Virtual Numbers for 2 Way SMS
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        {virtualNumbers &&
          virtualNumbers.map((config) => {
            return (
              <tr>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {config.virtual_number}
                    </Typography>
                    <Typography style={{ color: "grey" }}>
                      {`URL/${config.id}`}
                    </Typography>
                  </div>
                </td>
              </tr>
            );
          })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
