import React, { useState } from "react";
import Induction from "../Components/Induction";
import { summary_induction_links } from "../config/accountsummary";


export default function CxApp(props) {

  
  return (
    <div>
        b7kqYthsfaxR0FBNbAoM6P0hHwmJrkLbwdHaoAGv
    </div>
  );

}