import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ToolTip from "@material-ui/core/ToolTip";

class ActiveCampaignForm extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://vybrnt.activehosted.com/f/embed.php?id=45";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return <div className="_form_45" />;
  }
}

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ToolTip title="Support/Strategy Portal">
        <img
          className="zoombutton"
          alt="logo"
          src="https://sms-ui.vybrnt.com/static/media/question.3881ae1e.svg"
          onClick={handleClickOpen}
          style={{ position: "fixed", bottom: "80px", right: "90px" }}
        />
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="id845764">
          <ActiveCampaignForm />
        </DialogContent>
      </Dialog>
    </div>
  );
}
