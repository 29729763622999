import React, { useEffect } from "react";
import Constants from "../../Utils/Constants"
import SMSTemplates from "../SMSTemplates";
import HistoryTable from "./../HistoryTable";
import SMSCampaignStepper from "./../SMSCampaignStepper";
import CampaignHistory from "./../CampaignHistory";
import Conversations from "./../Conversations";
import Settings from "./../Settings";
import AccountSummary from "./../AccountSummary";
import NotFound from "./../NotFound";
import SetupWizard from "./../SetupWizard";
import "./../assets/index.css";

function Componentswitcher({user, path}) {

  const component = (user, path) => {
    switch (path) {
      case (Constants.admin) :
          return <AccountSummary name={user.firstname ? user.firstname : user.id} 
          />
      break
      case (Constants.smstable) :
          return  <SMSTemplates name={user.firstname ? user.firstname : user.id} 
          />
      break
      case (Constants.history) :
          return <HistoryTable name={user.firstname ? user.firstname : user.id}
          />
      break
      case (Constants.smsautomation) :
          return <SMSCampaignStepper name={user.firstname ? user.firstname : user.id}
          />
      break
      case (Constants.campaignhistory) :
          return <CampaignHistory name={user.firstname ? user.firstname : user.id}
          />
      break
      case (Constants.settings) :
          return <Settings name={user.firstname ? user.firstname : user.id}
          />
      break
      case (Constants.conversations) :
          return <Conversations name={user.firstname ? user.firstname : user.id}
          />
      break
      case (Constants.setupwizard) :
          return <SetupWizard name={user.firstname ? user.firstname : user.id}
          />
      break
      // to be set with 404
      default :
        return <NotFound name={user.firstname ? user.firstname : user.id}
        />
      break
    }
  }
  return component(user, path)
}

export default Componentswitcher;
