import React, { useState } from "react";


export default function ActiveCampiagn(props) {

  const [alias, setAlias]   = useState("");
  const [host, setHost]     = useState("");
  const [apikey, setApiKey] = useState("");

  return (
      <form>
        <div className="row">
          <div className="col-2">
            <label>Alias</label>  
          </div>
          <div className="col-10">
            <div className="form-groups">
            <input
              id="alias"
              value={alias}
              onChange={(event) => {
                setAlias(event.target.value);
              }}
              type="text"
              className="form-controls"
            />
          </div>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <label>API Host</label>
          </div>
          <div className="col-10">
            <div className="form-groups">
              <input
                id="host"
                value={host}
                onChange={(event) => {
                  setHost(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
          </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-2">
              <label>API Key</label>
          </div>
          <div className="col-10">
            <div className="form-groups">
              <input
                id="apikey"
                value={apikey}
                onChange={(event) => {
                  setApiKey(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </div>
          </div>
        </div>
      </form>
  );

}

