import React from "react";
import certificate from "./assets/certificate.svg";
import ToolTip from "@material-ui/core/ToolTip";

export default function CustomizedMenus(props) {
  return (
    <div style={{ display: "flex" }}>
      <ToolTip title="Delete Config">
        <img
          src={certificate}
          alt="certificate"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this config? This action is irreversible."
            );
            if (!result) {
              return;
            }
            props.onDeleteTemplateSubmit(props.row);
          }}
        />
      </ToolTip>
    </div>
  );
}
