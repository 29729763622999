import React from "react";
import { useNavigate } from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Constants from "./../../Utils/Constants";
import Header from "./../../assets/components/header";
import Induction from "../Components/Induction";

import LoadingProgress from "./../../Common/LoadingProgress";
import ACListOfLists from "../Components/ACListofLists";
import ACListofContacts from "../Components/ACListofContacts";
import BulkSMSForm from "./../Components/BulkSMSForm";
import ACListofConfigs from "../Components/ACListofConfigs";
import CampaignSMSPreview from "../Components/CampaignSMSPreview";
import {sms_templates_links} from "./../config/smscampaignstepper"

import "./assets/index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructionsDiv: {
    paddingTop: "30px",
  },
  stepper: {
    fontWeight: "600",
  },
  previewcard: {
    maxWidth: 345,
    margin: "15px",
    display: "grid",
  },
}));

export default function SMSCampaignStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [campaignName, setCampaignName] = React.useState(null);
  const [campaignDescription, setCampaignDescription] = React.useState(null);
  const [lists, setLists] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isStepperLoading, setIsStepperLoading] = React.useState(false);
  const [showEnd, setShowEnd] = React.useState(false);
  const [selectedEnv, setSelectedEnv] = React.useState(null);
  const [selectedList, setSelectedList] = React.useState(null);
  const [contacts, setContacts] = React.useState([]);
  const [bulkSMSConfig, setbulkSMSConfig] = React.useState({
    senderId: null,
    template: null,
  });
  const [useTwoWayNumber, setUseTwoWayNumber] = React.useState(false);
  const [selectedTwoWayNumber, setSelectedTwoWayNumber] = React.useState(null);
  const navigate = useNavigate();

  const user = {
    features: [],
    virtualNumbers: [{virtual_number: 'virtualNumber'}],
    acconfigs: []
  };

  const isTwoWaySMSEnabled =
    user &&
    user.features &&
    user.features.indexOf("TWO_WAY_SMS_ENABLED") != -1 &&
    user.virtualNumbers &&
    user.virtualNumbers.length > 0;

  const contactsWithPhone = contacts.filter((contact) => {
    return contact.phone && contact.phone.trim().length > 0;
  });

  const stepData = {
    0: {
      title: "Environment",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            Select from a configured Active Campaign Environment.
            <br /> If no environment is visible here, please configure atleast
            one in Settings {'>'} AC Config section
          </Typography>
          <ACListofConfigs
            configs={user.acconfigs}
            setSelectedEnv={setSelectedEnv}
          />
        </div>
      ),
      next: () => {
        if (!selectedEnv) {
          alert("Please select an Active Campaign Environment to proceed!");
          return;
        }
        setIsStepperLoading(true);
      },
    },
    1: {
      title: "List",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            Select an ActiveCampaign List
          </Typography>
          <Typography className={classes.instructions} style={{ color: "red" }}>
            * The list should contain atleast 1 and at the most 10,000 contacts
            with phone numbers.
          </Typography>
          <ACListOfLists lists={lists} setSelectedList={setSelectedList} />
        </div>
      ),
      next: () => {
        if (selectedList === null) {
          alert("Please select a list before proceeding to next step.");
          return;
        }
        setIsStepperLoading(true);
        
      },
    },
    2: {
      title: "Contacts",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            Following contacts were found in the list. Please review before
            proceeding. Note that contacts not having phone number will be
            skipped.
          </Typography>
          <div style={{ fontWeight: 800, fontSize: "larger" }}>
            Total Contacts in list: ({contacts.length})
          </div>
          <div style={{ fontWeight: 800, fontSize: "larger" }}>
            Contacts with phone number: ({contactsWithPhone.length})
          </div>
          <ACListofContacts contacts={contactsWithPhone} />
        </div>
      ),
      next: () => {},
    },
  };

  const getStepContent = (stepIndex) => {
    if (stepData[stepIndex]) {
      return stepData[stepIndex].content;
    } else {
      return "Unknown stepIndex";
    }
  };

  const handleSubmitCampaign = () => {
    if (!campaignName || campaignName.length === 0) {
      alert("Please enter a name for your campaign!");
      return;
    }
    if (
      !useTwoWayNumber &&
      (!bulkSMSConfig.senderId || bulkSMSConfig.senderId.length === 0)
    ) {
      alert("Sender Id is mandatory.");
      return;
    }
    if (
      !bulkSMSConfig ||
      !bulkSMSConfig.template ||
      bulkSMSConfig.template.length === 0
    ) {
      alert("SMS Template is are mandatory.");
      return;
    }
    if (bulkSMSConfig.senderId.length > 11) {
      alert("Sender Id should be less than or equal to 11 characters.");
      return;
    }
    if (!selectedEnv || selectedEnv.length === 0) {
      alert("Please select an environment and load contacts from it!");
      return;
    }
    if (!selectedList || selectedList.length === 0) {
      alert("Please select a list and load contacts from it!");
      return;
    }
    if (!contacts || contacts.length === 0) {
      alert("Please ensure that atleast one contact exists to which SMS can be sent!");
      return;
    }
    if (contactsWithPhone.length === 0 || contactsWithPhone.length > 10000) {
      alert("Total number of contacts with phone numbers in the list should be between 0 and 10,000.");
      return;
    }
    if (useTwoWayNumber && selectedTwoWayNumber === null) {
      alert("Please select a two-way SMS number to use!");
      return;
    }
    const result = window.confirm(
      "Are you sure you want to send this campaign? This will send SMS to all selected contacts."
    );
    if (!result) {
      return;
    }
    setIsLoading(true);
    
  };

  const handleNext = () => {
    stepData[activeStep].next();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCampaignName(null);
    setCampaignDescription(null);
    setLists([]);
    setSelectedEnv(null);
    setSelectedList(null);
    setContacts([]);
    setbulkSMSConfig({
      senderId: null,
      template: null,
    });
    setShowEnd(false);
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <div className={classes.root}>
      <Header name={"Sms Campaign"} />
      <Induction
        name={props.name}
        content={sms_templates_links}
      />
      {showEnd && (
        <Paper
          elevation={3}
          style={{
            margin: "0 auto",
            maxWidth: "700px",
            padding: "30px",
            marginBottom: "10px",
          }}
        >
          <div>
            <Typography className={classes.instructions}>
              <h3>All done!</h3>
            </Typography>
            <Typography>
              Your bulk SMS are being sent. You can check the task under SMS
              Campaign History screen for status.
            </Typography>
            <div style={{ paddingTop: "30px", textAlign: "end" }}>
              <span
                className="c-button text-uppercase"
                disabled={activeStep === -1}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  navigate(Constants.campaignhistory);
                }}
              >
                Go to Campaign History
              </span>
              <span
                className="c-button c-button--secondary text-uppercase"
                onClick={handleReset}
                style={{ marginRight: "10px" }}
              >
                Send another Campaign
              </span>
            </div>
          </div>
        </Paper>
      )}
      {!showEnd && (
        <div>
          <Paper
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <Typography className={classes.instructions}>
                Campaign Details
              </Typography>

              <form>
                <label>Give your Campaign a name!</label>
                <div className="form-groups">
                  <input
                    id="campaignName"
                    value={campaignName}
                    onChange={(event) => {
                      setCampaignName(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                  />
                </div>
                <label>Campaign Description (optional)</label>
                <div className="form-groups">
                  <textarea
                    rows="4"
                    id="campaignDescription"
                    className="form-controls"
                    value={campaignDescription}
                    onChange={(event) => {
                      setCampaignDescription(event.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
          </Paper>
          {isTwoWaySMSEnabled && (
            <Paper
              elevation={3}
              style={{
                margin: "0 auto",
                maxWidth: "700px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <Typography className={classes.instructions}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useTwoWayNumber}
                          onChange={() => {
                            setUseTwoWayNumber(!useTwoWayNumber);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Use a Two-Way SMS Number"
                    />
                  </FormGroup>
                  {useTwoWayNumber && (
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "30px" }}
                    >
                      <RadioGroup
                        name="selectedTwoWayNumber"
                        value={selectedTwoWayNumber}
                        onChange={(event) => {
                          setSelectedTwoWayNumber(event.target.value);
                        }}
                      >
                        {user &&
                          user.virtualNumbers &&
                          user.virtualNumbers.map((virtualNumber) => {
                            return (
                              <FormControlLabel
                                value={virtualNumber.virtual_number}
                                control={<Radio color="primary" />}
                                label={virtualNumber.virtual_number}
                                disabled={!useTwoWayNumber}
                              />
                            );
                          })}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Typography>
              </div>
            </Paper>
          )}
          <Paper
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <Typography className={classes.instructions}>
                SMS Details
              </Typography>
              <BulkSMSForm
                setbulkSMSConfig={setbulkSMSConfig}
                useTwoWayNumber={useTwoWayNumber}
              />
            </div>
          </Paper>
          <Paper
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ margin: "0 auto", maxWidth: "700px" }}>
              <Typography className={classes.instructions}>
                Contact Details
              </Typography>
              {true && (
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  //connector={<QontoConnector />}
                >
                  {Object.keys(stepData).map((key) => {
                    const data = stepData[key];
                    return (
                      <Step key={data.title}>
                        <StepLabel className={classes.stepper}>
                          {data.title}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
              <div>
                {isStepperLoading && <LoadingProgress />}
                {
                  <div style={{ margin: "0 auto", maxWidth: "700px" }}>
                    {getStepContent(activeStep)}
                    <div
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "20px",
                        textAlign: "end",
                      }}
                    >
                      {activeStep !== 0 && (
                        <span
                          className="c-button text-uppercase"
                          disabled={activeStep === -1}
                          style={{ marginRight: "10px" }}
                          onClick={handleBack}
                        >
                          Back
                        </span>
                      )}
                      {activeStep !== Object.keys(stepData).length - 1 && (
                        <span
                          className="c-button c-button--secondary text-uppercase"
                          onClick={handleNext}
                          style={{ marginRight: "10px" }}
                        >
                          Next
                        </span>
                      )}
                      {activeStep === Object.keys(stepData).length - 1 && (
                        <CampaignSMSPreview
                          contactsWithPhone={contactsWithPhone}
                          bulkSMSConfig={bulkSMSConfig}
                          useTwoWayNumber={useTwoWayNumber}
                          selectedTwoWayNumber={selectedTwoWayNumber}
                        />
                      )}
                      {activeStep === Object.keys(stepData).length - 1 && (
                        <span
                          onClick={handleSubmitCampaign}
                          className="c-button c-button_secondary"
                          style={{ marginLeft: "10px" }}
                        >
                          Send Campaign!
                        </span>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}
