import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ToolTip from "@material-ui/core/ToolTip";

import edit from "./assets/edit.svg";
import certificate from "./assets/certificate.svg";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CHARACTER_LIMIT = 160;

export default function ActionButtonWithMenu(props) {
  const [open, setOpen] = React.useState(false);
  const [templatetext, setTemplatetext] = React.useState("");
  const [name, setName] = React.useState("");
  const [senderId, setSenderId] = React.useState("");

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setTemplatetext("");
    setName("");
    setSenderId("");
  };

  const handleDialogSubmit = () => {
    if (
      !templatetext ||
      templatetext.length === 0 ||
      !name ||
      name.length === 0 ||
      !senderId ||
      senderId.length === 0
    ) {
      alert("All fields are mandatory!");
      return;
    }
    if (senderId.length > 11) {
      alert("Sender Id should be less than or equal to 11 characters.");
      return;
    }
    setOpen(false);
    const editedObj = { ...props.row };
    editedObj.template_text = templatetext;
    editedObj.template_name = name;
    editedObj.template_senderid = senderId;
    props.onEditTemplateSubmit(editedObj);
    setTemplatetext("");
    setName("");
    setSenderId("");
  };

  return (
    <div>
      <ToolTip title="Edit Template">
        <img
          src={edit}
          alt="edit"
          onClick={() => {
            //logger.log("props.row", props.row);
            setTemplatetext(props.row.template_text);
            setName(props.row.template_name);
            setSenderId(props.row.template_senderid);
            handleDialogOpen();
          }}
        />
      </ToolTip>
      <ToolTip title="Delete Template">
        <img
          src={certificate}
          alt="certificate"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this SMS template? This action is irreversible."
            );
            if (!result) {
              return;
            }
            props.onDeleteTemplateSubmit(props.row);
          }}
        />
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Edit SMS Template</div>
            <button type="button" className="close" onClick={handleDialogClose}>
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Name</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="name"
                  value={name}
                  className="form-controls"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>

              <label>Sender Id</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="senderId"
                  value={senderId}
                  className="form-controls"
                  onChange={(event) => {
                    setSenderId(event.target.value);
                  }}
                />
              </div>

              <label>SMS Template</label>
              <div className="form-groups">
                <textarea
                  rows="4"
                  id="formSmsTemplate"
                  className="form-controls"
                  value={templatetext}
                  onChange={(event) => {
                    setTemplatetext(event.target.value);
                  }}
                  inputProps={{
                    maxLength: CHARACTER_LIMIT,
                  }}
                  helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
                />
              </div>
              <div className="text-limit">
                {templatetext.length}/{CHARACTER_LIMIT}
              </div>
            </form>
          </DialogContent>

          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleDialogClose}
              className="c-button c-button_secondary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDialogSubmit}
              className="c-button c-button_primary"
            >
              Save
            </button>
          </DialogActions>
          {/* Dialog Action Buttons End */}
        </div>
      </Dialog>

      {/* Dialog End */}
    </div>
  );
}
