import React from "react";

import Header from "../Components/header";
import Hero from "../Components/hero";
import Services from "../Components/services";
import Mailcta from "../Components/mailcta";
import Getintouch from "../Components/getintouch";
import Smstips from "./../Components/smstips";
import Comingsoon from "./../Components/comingsoon";
import Footer from "./../Components/footer";

function Home(props) {

  React.useEffect(() => {

    // Run loader at the beginning, and then remove
    let preloader = document.getElementById("preloader");
    if(preloader) {
      setTimeout(preloader.remove(), 3000);
    }

    // Add background animation to header on scroll
    const element = document.getElementById("header");
    window.addEventListener("scroll", (event) => {
      let position = (document.body.getBoundingClientRect().top * (-1))
      if (position > 100) {
        element.classList.add("header-scrolled");
      } else {
        element.classList.remove("header-scrolled");
      }
  });
}, []);

  return (
    <div className="body html">
      <Header />
      <Hero />
      <Services />
      <Mailcta />
      <Getintouch />
      <Smstips />
      <Comingsoon />
      <Footer />
      <div id="preloader" />
    </div>
  );
}

export default Home;
