import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferOutlined from "@material-ui/icons/LocalOfferOutlined";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Zoom from "@material-ui/core/Zoom";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default function LongMenu(props) {
  const [wordFilter, setWordFilter] = useState("");

  const getAddTagInput = (optionsToShowInMenu) => {
    return (
      <Input
        inputRef={(input) => {
          input && input.focus();
        }}
        style={{ minWidth: "110px", width: "0px", marginLeft: "10px" }}
        placeholder="Add Label"
        value={wordFilter}
        onChange={(event) => {
          const value = event.target.value;
          event.currentTarget.parentNode.style.width =
            (value.length + 1) * 12 + "px";
          setWordFilter(value);
        }}
        onKeyPress={(event) => {
          const value = event.target.value;
          if (event.key == "Enter") {
            if (
              optionsToShowInMenu.indexOf(value) === -1 &&
              props.tagSet.indexOf(value) === -1
            ) {
              props.addTagToTagSet(value);
            }
            props.addTagToMovie(value);
            setWordFilter("");
          }
        }}
      />
    );
  };

  const getTagChip = (option) => {
    return (
      <Chip
        label={option}
        variant="outlined"
        onClick={(e) => {
          props.addTagToMovie(option);
          setWordFilter("");
        }}
      />
    );
  };

  const getMenuAsGrid = () => {
    const tagsToShowInMenu = props.tagSet.filter((element) => {
      return (
        props.selected.indexOf(element) === -1 &&
        element.toLowerCase().indexOf(wordFilter.toLowerCase()) !== -1
      );
    });
    const componentsToShowInMenu = [
      <Grid item xs key={"addtaginput"}>
        {getAddTagInput(tagsToShowInMenu)}
      </Grid>,
    ];

    tagsToShowInMenu.forEach((option) => {
      componentsToShowInMenu.push(
        <Grid item xs key={option}>
          {getTagChip(option)}
        </Grid>
      );
    });

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          {componentsToShowInMenu.map((option) => option)}
        </Box>
      </ClickAwayListener>
    );
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const toggleTooltip = () => {
    setOpen(!open);
  };

  const getMenuWithIcon = () => {
    return (
      <div style={{ display: "inline-block" }}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          onOpen={handleTooltipOpen}
          //disableFocusListener
          //disableHoverListener
          //disableTouchListener
          title={getMenuAsGrid()}
          interactive
          enterDelay={10}
          leaveDelay={10}
          arrow
        >
          <IconButton onClick={toggleTooltip}>
            <FolderOpenIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return getMenuWithIcon();
}
