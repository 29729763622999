import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";

import LoadingProgress from "./../../../Common/LoadingProgress";

import AddACConfigButton from "./AddACConfigButton";
import ActionButtonWithMenu from "./ActionButtonWithMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels() {
  const user = {
    cxapptokens: []
  };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cxapptokens, setCxapptokens] = React.useState(user.cxapptokens);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const addCXAppToken = () => {
    
    if (cxapptokens && cxapptokens.length >= 5) {
      alert("You can only add 5 cxapptokens");
      return;
    }
    setIsLoading(true);
    
  };

  const onDeleteTemplateSubmit = (row) => {
    setIsLoading(true);
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>CX App Api Keys</Typography>
        <Typography className={classes.secondaryHeading}>
          Manage Api Keys for CX Apps
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        {cxapptokens &&
          cxapptokens.map((config) => {
            return (
              <tr>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {config.id}
                    </Typography>
                    <Typography style={{ color: "grey" }}>
                      {config.createdAt}
                    </Typography>
                  </div>
                </td>
                <td>
                  <div className="o-icon">
                    <ActionButtonWithMenu
                      row={config}
                      onDeleteTemplateSubmit={onDeleteTemplateSubmit}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
      </ExpansionPanelDetails>
      {(!cxapptokens || (cxapptokens && cxapptokens.length < 5)) && (
        <div>
          <Divider />
          <ExpansionPanelActions>
            <AddACConfigButton addCXAppToken={addCXAppToken} />
          </ExpansionPanelActions>
        </div>
      )}
    </ExpansionPanel>
  );
}
